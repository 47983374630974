<template>
	<v-container fluid class="pa-0">
		<OnboardingComponent />
	</v-container>
</template>

<script>
import OnboardingComponent from '@/components/onboarding/Index.vue';

export default {
	name: 'Onboarding',
	components: {
		OnboardingComponent,
	},
};
</script>

<style lang="scss" scoped>
.container {
	height: 100%;
}
</style>
