<template>
	<v-row style="height: 100%">
		<v-col md="4" cols="12" class="column1">
			<div id="selectiontrial-onboarding" class="text-center d-flex flex-column justify-center">
				<div class="mb-12">
					<div class="onboarding-progress-bar-step-div">
						<div class="onboarding-progress-bar-step-sub-div">
							<v-progress-linear
								v-model="stepProgressBarBufferValue"
								:buffer-value="stepProgressBar"
								striped
								rounded
								color="#15c39a"
								height="9"
							></v-progress-linear>
						</div>
					</div>

					<h1 class="animate__animated animate__tada mt30">Bienvenue {{ user.first_name }}</h1>
					<h2 class="font-weight-black mt-6 mt-md-6 mb-2 mb-md-2">Pour lancer ta formation</h2>

					<div class="ortho-button-selection-trial-subline-container">
						<p>Clique sur le bouton ci-dessous</p>
						<img src="@/assets/icons/emoji/emoji-finger-down.png" alt="speaker" class="fingerDownEmoji" />
					</div>

					<v-row>
						<v-col
							v-for="(element, index) in periodTrial"
							:key="index"
							cols="12"
							:class="{
								'mx-auto': periodTrial.length == 1,
							}"
						>
							<v-card
								class="pa-2 d-flex flex-column justify-space-around card-selection-trial"
								style="height: 100px"
								outlined
								rounded
								tile
								@click="selectElement(element)"
								:class="{
									selected: element.checked,
								}"
							>
								<p class="mb-0 mb-md-2">{{ element.description }}</p>
								<img
									v-if="element.checked"
									src="@/assets/icons/correct-skyblue.svg"
									alt="checked element"
									class="element-selected-icon mr-2 mb-2"
								/>
							</v-card>
						</v-col>
					</v-row>
					<v-alert v-if="error" type="error">Une erreur s'est produite</v-alert>
					<v-btn :disabled="!valid" depressed large dark block class="mt-12 ml-auto mr-auto mb-12" @click="nextStep()"
						>C'est parti</v-btn
					>
				</div>
			</div>
		</v-col>
		<v-col md="8" cols="12" class="column2">
			<div class="background-grey d-none d-md-flex justify-center align-center">
				<img src="@/assets/images/trialPicture.png" alt="trialPicture" class="trialPicture" />
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'OnboardingSelectionTrial',
	data() {
		return {
			stepProgressBar: 100,
			stepProgressBarBufferValue: 0,
			stepProgressBarLimit: 20,

			error: false,
			periodTrial: [
				{
					description: 'Lancer ma formation',
					checked: false,
					days: 5,
				},
			],
		};
	},
	props: {
		user: Object,
	},
	computed: {
		valid() {
			return this.periodTrial.find((single) => {
				return single.checked == true;
			});
		},
	},
	mounted() {
		window.scrollTo(0, 0);

		//start progress bar animation
		this.startBuffer();
	},
	methods: {
		startBuffer() {
			setInterval(() => {
				this.stepProgressBarBufferValue = this.stepProgressBarLimit;
			}, 500);
		},
		selectElement(element) {
			const divSelected = this.periodTrial.find((single) => {
				return single == element;
			});
			const divNotSelected = this.periodTrial.filter((single) => {
				return single != element;
			});
			divNotSelected.map((element) => {
				element.checked = false;
			});
			divSelected.checked = true;
		},
		async nextStep() {
			const trialSelected = this.periodTrial.filter((single) => {
				return single.checked == true;
			});
			try {
				await this.$store.dispatch('onboarding/registerPeriodTrial', {
					idUser: this.$route.params.idUser,
					trialSelected,
				});
				if (process.env.VUE_APP_MIXPANEL == 'production') {
					this.$mixpanel.track('onboarding-selectionTrial');
				}
				if (this.user.subscription_mode == 'google' || this.user.subscription_mode == 'facebook') {
					this.$emit('nextStep', 'Intro');
				} else if (this.user.has_changed_password !== true) {
					this.$emit('nextStep', 'ResetPassword');
				} else if (this.user.subscription_mode != 'formulaire') {
					this.$emit('nextStep', 'Intro');
				} else {
					this.$emit('nextStep', 'ResetPassword');
				}
			} catch (error) {
				this.error = true;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.ortho-button-selection-trial-subline-container {
	text-align: center;
	display: flex;
	justify-content: center;
}

.fingerDownEmoji {
	margin-top: 2px;
	width: 20px;
	height: 20px;
	margin-left: 5px;
}
.trialPicture {
	width: 35rem;
}
.column1 {
	padding-right: 0px !important;
	padding-top: 0px !important;
	padding-bottom: 0 !important;
	padding-left: 0px !important;
}
.column2 {
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
	padding-bottom: 0 !important;
}
.fullContainer {
	width: 100% !important;
	height: 100% !important;
}
.background-grey {
	background-color: #6afdef;
	border-top-left-radius: 50px;
	width: 100%;
	height: 100%;
}

.row {
	margin: 0px;
}

#selectiontrial-onboarding {
	padding: 30px;
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	button {
		width: 60%;
	}
	.element-selected-icon {
		width: 25px;
		position: absolute;
		bottom: 0px;
		right: 0px;
	}
	.v-card {
		position: relative;
		height: 10rem;
		border-radius: 4px !important;
		&.selected {
			border: 2px solid #6afdef !important;
		}
	}
}
@media screen and (max-width: 600px) {
	.column2 {
		display: none;
	}
}
@media screen and (max-width: 960px) {
	.column2 {
		display: none;
	}
}
</style>
