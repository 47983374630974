<template>
	<div id="intro-onboarding" class="text-center d-flex flex-column justify-center align-center">
		<div class="mb-12">
			<div class="carouselContainer">
				<v-carousel
					hide-delimiters
					progress-color="#6afdef"
					height="600"
					hide-delimiter-background
					v-model="slides"
					:show-arrows="show.arrows"
					:next-icon="show.nextArrow"
					:prev-icon="show.prevArrow"
				>
					<v-carousel-item>
						<v-sheet
							color="transparent"
							class="sheetContainer text-center d-flex flex-column justify-center align-center"
						>
							<div>
								<h2 class="black--text">Bonjour {{ user.first_name }}</h2>
								<h1 class="font-weight-black black--text welcomeText">
									Bienvenue sur <span class="black--text underlineText">OrthographIQ</span>
								</h1>
								<p class="mt-10 black--text">Clique sur la flèche à droite pour en savoir plus</p>
							</div>
						</v-sheet>
					</v-carousel-item>
					<v-carousel-item>
						<v-sheet
							color="transparent"
							class="sheetContainer text-center d-flex flex-column justify-center align-center"
						>
							<div align="center" justify="center">
								<img src="@/assets/images/onboardingLogo/mailbox.png" alt="mailbox" class="onboardingIcon" />
								<h1 class="font-weight-black black--text slideText">
									La formation qui arrive <span class="underlineText">tous les matins</span><br />
									dans ta boîte mail
								</h1>
								<h1 class="black--text mt-10 subSlideText">(Sauf le week-end)</h1>
							</div>
						</v-sheet>
					</v-carousel-item>
					<v-carousel-item>
						<v-sheet
							color="transparent"
							class="sheetContainer text-center d-flex flex-column justify-center align-center"
						>
							<div align="center" justify="center">
								<img
									src="@/assets/images/onboardingLogo/stopwatch.png"
									alt="stopwatch"
									style="width: 12%"
									class="mb10"
								/>
								<h1 class="font-weight-black black--text slideText">
									<span class="underlineText">8 min par jour</span> seulement
								</h1>
								<h1 class="black--text subSlideText">pour écrire sans fautes dès demain !</h1>
							</div>
						</v-sheet>
					</v-carousel-item>
					<v-carousel-item>
						<v-sheet
							color="transparent"
							class="sheetContainer text-center d-flex flex-column justify-center align-center"
						>
							<div align="center" justify="center">
								<img src="@/assets/images/onboardingLogo/artpalet.png" alt="artpalet" class="onboardingIcon" />
								<h1 class="font-weight-black black--text slideText">Personnalise ton parcours dès maintenant !</h1>
								<v-btn style="font-size: 20px" depressed large dark class="mt-10 mt-md-12 hvr-grow" @click="nextStep()"
									>Commencer</v-btn
								>
							</div>
						</v-sheet>
					</v-carousel-item>
				</v-carousel>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'OnboardingIntro',
	data() {
		return {
			colors: ['indigo', 'warning', 'pink darken-2', 'red lighten-1', 'deep-purple accent-4'],
			slides: 0,
			show: {
				arrows: true,
				nextArrow: 'mdi mdi-chevron-right',
				prevArrow: 'mdi mdi-chevron-left',
			},
		};
	},
	props: {
		user: Object,
	},
	watch: {
		slides: function (newStep) {
			this.show.nextArrow = newStep != 3 ? 'mdi mdi-chevron-right' : false;
			this.show.prevArrow = newStep != 0 ? 'mdi mdi-chevron-left' : false;
		},
	},
	mounted() {
		window.scrollTo(0, 0);
		if (this.slides == 0) {
			this.show.prevArrow = false;
		}
	},
	methods: {
		nextStep() {
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('onboarding-intro');
			}
			this.$emit('nextStep', 'QuestionsSociales');
		},
	},
};
</script>

<style lang="scss" scoped>
.onboardingIcon {
	width: 8%;
	margin-bottom: 10px;
}
.slideText {
	font-size: 40px;
}
.subSlideText {
	font-size: 30px;
}
.welcomeText {
	font-size: 60px;
}
.sheetContainer {
	height: 100%;
	margin: 0 auto;
	padding: 50px;
}
.carouselContainer {
	width: 1000px;
}
#intro-onboarding {
	height: 100%;
	margin: 0 auto;
	button {
		widows: 60%;
	}
}

@media screen and (max-width: 600px) {
	.welcomeText {
		font-size: 40px;
	}
	.slideText {
		font-size: 30px;
	}
	.subSlideText {
		font-size: 20px;
	}
	.carouselContainer {
		width: 100%;
	}
	.sheetContainer {
		padding: 50px;
	}
}
</style>
