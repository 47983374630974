<template>
	<v-row id="infos-user-onboarding" class="mr-0 ml-0">
		<v-col cols="12" md="6" class="col-qs text-center d-flex flex-column justify-center">
			<div class="onboarding-progress-bar-step-div">
				<div class="onboarding-progress-bar-step-sub-div">
					<v-progress-linear
						v-model="stepProgressBarBufferValue"
						:buffer-value="stepProgressBar"
						striped
						rounded
						color="#15c39a"
						height="9"
					></v-progress-linear>
				</div>
			</div>
			<h1 class="mt-4 mt-md-0 mb-5 mb-md-12">
				<span class="underlineText">Activation</span> et vérification du compte
			</h1>
			<v-form v-model="valid">
				<v-text-field
					v-model="firstName"
					label="Prénom *"
					outlined
					:rules="[(v) => !!v || 'Saisis ton prénom']"
				></v-text-field>
				<v-text-field
					v-model="lastName"
					label="Nom *"
					class="mb-4 mt-4"
					outlined
					:rules="[(v) => !!v || 'Saisis ton nom']"
				></v-text-field>
				<v-text-field
					v-model="phone"
					type="number"
					label="Numéro de téléphone *"
					prefix="+33"
					outlined
					:rules="[(v) => !!v || 'Saisis ton numéro']"
				></v-text-field>
			</v-form>
			<v-btn
				:disabled="!valid"
				depressed
				large
				dark
				width="50%"
				class="mt-12 ml-auto mr-auto hvr-grow"
				@click="nextStep()"
				>Suivant</v-btn
			>
		</v-col>
		<v-col cols="12" md="6" class="backgrounded d-none d-md-flex justify-center align-center">
			<img src="@/assets/images/girl-phone.png" id="images-qs" alt="questions-sociales" />
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'OnboardingConfirmation',
	props: {
		user: Object,
	},
	data() {
		return {
			stepProgressBar: 100,
			stepProgressBarBufferValue: 50,
			stepProgressBarLimit: 70,

			valid: false,
			phone: this.user.phoneNumber ? this.user.phoneNumber.toString() : '',
			firstName: this.user.first_name,
			lastName: this.user.last_name,
		};
	},
	mounted() {
		window.scrollTo(0, 0);

		//start progress bar animation
		this.startBuffer();
	},
	methods: {
		startBuffer() {
			setInterval(() => {
				this.stepProgressBarBufferValue = this.stepProgressBarLimit;
			}, 500);
		},
		async nextStep() {
			await this.$store.dispatch('onboarding/lastInfos', {
				lastName: this.lastName,
				firstName: this.firstName,
				phone: this.phone,
			});
			const res = await this.$store.dispatch('onboarding/registerInfosOnboarding', {
				idUser: this.$route.params.idUser,
			});
			if (res.status == 200) {
				if (process.env.VUE_APP_MIXPANEL == 'production') {
					this.$mixpanel.track('onboarding-confirmationAccount');
				}
				this.$emit('nextStep', 'Finalisation');
			}
		},
	},
};
</script>

<style lang="scss">
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield !important;
}
::v-deep {
	.v-input--selection-controls .v-radio > .v-label {
		color: black !important;
	}
}
.backgrounded {
	border-top-left-radius: 50px;
	background-color: rgba(169, 169, 169, 0.1);
}
#infos-user-onboarding {
	height: 100%;
	.col-qs {
		padding: 2rem 9rem 6rem 9rem;
		h1 {
			font-size: 40px;
		}
	}
	#images-qs {
		width: 25rem;
	}
	.theme--dark.v-btn.v-btn--disabled {
		background: black !important;
	}
	#form {
		height: fit-content;
	}
}
@media screen and (max-width: 600px) {
	#infos-user-onboarding {
		.col-qs {
			padding: 2rem;
			height: 100%;
			h1 {
				font-size: 27px;
			}
		}
	}
}
</style>
