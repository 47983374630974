<template>
	<v-row id="qsociales-onboarding" class="mr-0 ml-0">
		<v-col cols="12" md="6" class="col-qs text-center d-flex flex-column justify-center">
			<div class="onboarding-progress-bar-step-div">
				<div class="onboarding-progress-bar-step-sub-div">
					<v-progress-linear
						v-model="stepProgressBarBufferValue"
						:buffer-value="stepProgressBar"
						striped
						rounded
						color="#15c39a"
						height="9"
					></v-progress-linear>
				</div>
			</div>
			<h1 class="ml-5 mr-6 mt-5 mb-10">
				<span class="underlineText">Personnalise ton parcours</span>
			</h1>
			<v-form v-model="valid" id="form" class="d-flex flex-column pl-12 pr-12">
				<label class="ortho-text-gray">Sélectionne ton sexe *</label>
				<v-radio-group v-model="genderGroup" :rules="[(v) => !!v || 'Selectionne ton sexe']" required row>
					<v-radio label="Femme" value="F" color="#6afdef"></v-radio>
					<v-radio label="Homme" value="M" color="#6afdef"></v-radio>
					<v-radio label="Autre" value="A" color="#6afdef"></v-radio>
				</v-radio-group>
				<div id="birth-date" class="mt-10 mb-10 pt-4 pb-4 pl-2 pr-2">
					<label class="ortho-text-gray">Date de naissance *</label>
					<v-menu
						ref="menu"
						v-model="menu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="dateFormatted"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
								:rules="[(v) => !!v || 'Selectionne ta date de naissance']"
							></v-text-field>
						</template>
						<v-date-picker
							ref="picker"
							v-model="date"
							:first-day-of-week="1"
							locale="fr-FR"
							color="black"
							:max="new Date().toISOString().substr(0, 10)"
							@change="save"
						></v-date-picker>
					</v-menu>
				</div>
				<label class="ortho-text-gray">Sélectionne une réponse *</label>
				<v-row justify="space-around">
					<v-radio-group v-model="natifGroup">
						<v-radio label="Je suis français natif" :value="true" color="#6afdef"></v-radio>
						<v-radio label="Je ne suis pas français natif" :value="false" color="#6afdef"></v-radio>
					</v-radio-group>
				</v-row>
			</v-form>
			<v-btn
				:disabled="!valid"
				depressed
				large
				dark
				width="30%"
				class="mt-12 ml-auto mr-auto hvr-grow mb-3 mb-md-0"
				@click="nextStep()"
				>Suivant</v-btn
			>
		</v-col>
		<v-col cols="12" md="6" class="backgrounded d-none d-md-flex justify-center align-center">
			<img src="@/assets/images/diagnostic.png" id="images-qs" alt="questions-sociales" />
		</v-col>
	</v-row>
</template>

<script>
import moment from 'moment';

export default {
	name: 'OnboardingQsSociales',
	props: {
		user: Object,
	},
	data() {
		return {
			stepProgressBar: 100,
			stepProgressBarBufferValue: 20,
			stepProgressBarLimit: 30,

			genderGroup: this.user.gender,
			natifGroup: true,
			date: this.user.birth_date ? moment(this.user.birth_date).format('YYYY-MM-DD') : null,
			menu: false,
			valid: false,
		};
	},
	watch: {
		menu(val) {
			val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
		},
	},
	mounted() {
		window.scrollTo(0, 0);

		//start progress bar animation
		this.startBuffer();
	},
	computed: {
		dateFormatted() {
			return this.formatDate(this.date);
		},
	},
	methods: {
		startBuffer() {
			setInterval(() => {
				this.stepProgressBarBufferValue = this.stepProgressBarLimit;
			}, 500);
		},
		async nextStep() {
			await this.$store.dispatch('onboarding/registerSocialQs', {
				gender: this.genderGroup,
				natif: this.natifGroup,
				birthDate: this.date,
			});
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('onboarding-questionsSociales');
			}
			this.$emit('nextStep', 'Objectives');
		},
		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		save(date) {
			this.$refs.menu.save(date);
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.v-input--selection-controls .v-radio > .v-label {
		color: black !important;
	}
	.v-input__slot {
		margin: 0px;
	}
	.v-text-field__details {
		margin-top: 15px;
	}
	div[role='radiogroup'] {
		display: flex;
		justify-content: center;
	}
}
.backgrounded {
	border-top-left-radius: 50px;
	background-color: rgba(169, 169, 169, 0.1);
}
#qsociales-onboarding {
	height: 100%;
	.col-qs {
		// background-color: rgba(169, 169, 169, 0.1);
		height: 100%;
		h1 {
			font-size: 40px;
		}
	}
	#images-qs {
		width: 25rem;
	}
	#birth-date {
		width: 50%;
		margin: 0 auto;
		.v-input {
			margin: 0px;
			padding: 0px;
		}
	}
	.theme--dark.v-btn.v-btn--disabled {
		background: grey !important;
	}
	#form {
		height: fit-content;
	}
}

.row {
	margin: 0px !important;
}

.qsociales-onboarding-subTitle {
	font-size: 20px;
}
@media screen and (max-width: 600px) {
	#qsociales-onboarding {
		height: 100%;
		.col-qs {
			h1 {
				font-size: 27px;
			}
		}
		#birth-date {
			width: 90%;
		}
	}
}
</style>
