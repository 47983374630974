<template>
	<div class="text-center">
		<div class="backgroundGrey"></div>

		<div class="mt-4 mt-md-0 mb-5 mb-md-12">
			<div class="onboarding-progress-bar-step-div">
				<div class="onboarding-progress-bar-step-sub-div">
					<v-progress-linear
						v-model="stepProgressBarBufferValue"
						:buffer-value="stepProgressBar"
						striped
						rounded
						color="#15c39a"
						height="9"
					></v-progress-linear>
				</div>
			</div>
			<h1>Sélectionne tes <span class="underlineText">objectifs</span></h1>
			<span class="ortho-text-gray">Choisis au minimum 1 objectif</span>
		</div>
		<v-row>
			<v-col v-for="(element, index) in objectives" :key="index" cols="6" md="4">
				<v-card
					class="pa-2 justify-center"
					outlined
					rounded
					tile
					@click="selectElement(element)"
					:class="{
						selected: element.checked,
					}"
				>
					<div style="position: relative; top: 50%; transform: translateY(-50%)">
						<h2>{{ element.title }}</h2>
						<p class="mb-0 mb-md-2">{{ element.description }}</p>
					</div>
					<img
						v-if="element.checked"
						src="@/assets/icons/correct-skyblue.svg"
						alt="checked element"
						class="element-selected-icon mr-2 mb-2"
					/>
				</v-card>
			</v-col>
			<v-btn :disabled="!valid" depressed large dark class="mt-12 ml-auto mr-auto mb-12 hvr-grow" @click="nextStep()"
				>Enregistrer</v-btn
			>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'OnboardingObjectives',
	data() {
		return {
			stepProgressBar: 100,
			stepProgressBarBufferValue: 30,
			stepProgressBarLimit: 50,

			objectives: [
				{
					title: 'Vocabulaire',
					description: 'Je veux enrichir mon vocabulaire',
					checked: false,
				},
				{
					title: 'Orthographe',
					description: 'Je veux les bons réflexes pour minimiser mes erreurs',
					checked: false,
				},
				{
					title: 'Relecture',
					description: 'Je veux être plus efficace dans mes relectures',
					checked: false,
				},
				{
					title: 'Syntaxe',
					description: 'Je veux être sûr de mes formulations et mes tournures de phrase',
					checked: false,
				},
				{
					title: 'Grammaire',
					description: 'Je veux savoir accorder le participe passé',
					checked: false,
				},
				{
					title: 'Rapidité',
					description: 'Je veux être plus efficace dans mes écrits',
					checked: false,
				},
			],
		};
	},
	mounted() {
		window.scrollTo(0, 0);

		//start progress bar animation
		this.startBuffer();
	},
	computed: {
		valid() {
			return this.objectives.find((single) => {
				return single.checked == true;
			});
		},
	},
	methods: {
		startBuffer() {
			setInterval(() => {
				this.stepProgressBarBufferValue = this.stepProgressBarLimit;
			}, 500);
		},
		selectElement(element) {
			const divSelected = this.objectives.find((single) => {
				return single == element;
			});
			divSelected.checked = !element.checked;
		},
		async nextStep() {
			const objectivesSelected = this.objectives.filter((single) => {
				return single.checked == true;
			});
			await this.$store.dispatch('onboarding/registerObjectives', {
				objectivesSelected,
			});
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('onboarding-selectionObjectives');
			}
			this.$emit('nextStep', 'Confirmation');
		},
	},
};
</script>

<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
	background: grey !important;
}
.row {
	width: 60%;
	margin: 0 auto;
	.v-card {
		position: relative;
		height: 10rem;
		border-radius: 10px !important;
		&.selected {
			border: 2px solid #6afdef !important;
		}
		.element-selected-icon {
			width: 25px;
			position: absolute;
			bottom: 0px;
			right: 0px;
		}
	}
}
.backgroundGrey {
	width: 100%;
	height: 30rem;
	position: absolute;
	bottom: 0px;
	left: 0px;
	background-color: rgba(169, 169, 169, 0.1);
}
button {
	width: 30%;
}
@media screen and (max-width: 600px) {
	h1 {
		font-size: 27px;
	}
	h2 {
		font-size: 20px;
	}
	.row {
		width: 90%;
	}
	button {
		width: 50%;
	}
}
</style>
